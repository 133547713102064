import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

const FrenchData = () => {
  const summaryOfFindings = [
    "-La plupart des mots français ont sept lettres.",
    '-La première lettre la plus courante des mots français est "c", suivie de "p".',
    '-La dernière lettre la plus courante des mots français est "s" (dû en partie au fait que la plupart des noms et adjectifs au pluriel se terminent par "s"), suivie de "e" (grâce, entre autres, au fait que la plupart des noms et adjectifs au féminin se terminent par "e").',
    '-La lettre la plus courante trouvée dans les mots français est la voyelle "e", suivie de la consonne "r", puis les voyelles "i" et "a".',
    "-Beaucoup des mots français les plus courants sont des articles, des pronoms et des prépositions. Cependant, il y a très peu de ces types de mots en français, donc à mesure que la liste des mots fréquemment utilisés grandit, la plupart des mots de la liste sont des noms, des verbes, des adverbes et des adjectifs.",
  ];
  return (
    <div>
      <Header />
      <Container maxWidth="md">
        <Box py={2}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h4"
                color="black"
                align="center"
                sx={{ marginTop: 6 }}
              >
                FRÉQUENCES DES LETTRES ET MOTS FRANÇAIS{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginBottom: 5 }}
              >
                UNE ANALYSE AVEC PYTHON (PANDAS ET MATPLOTLIB){" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h7"
                color="black"
                align="left"
                sx={{ fontStyle: "italic" }}
              >
                L'ensemble de données utilisé dans cette analyse se trouve{" "}
                <a href="https://en.wiktionary.org/wiki/Wiktionary:Frequency_lists/French/OpenSubtitles_Top_20K">
                  ici
                </a>
                . L'analyse est disponible sous la licence{" "}
                <a href="https://www.apache.org/licenses/LICENSE-2.0">
                  Apache 2.0
                </a>
                .
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="center">
                - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                - -
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black">
                Le jeu de données utilisé dans cette analyse répertorie les{" "}
                <strong>20.000</strong> mots français les plus courants (ce qui
                équivaut à peu près au nombre de mots - si "mot" veut dire "
                <a href="https://fr.wikipedia.org/wiki/Lex%C3%A8me">lexème</a>"
                - qu'un francophone natif utilise régulièrement) classés selon
                leur fréquence d'apparition dans les sous-titres des films
                français. Les <strong>20</strong> premières rangées du jeu de
                données (montrant les <strong>20</strong> mots français les plus
                courants) sont énumérés ci-dessous dans le{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Tableau 1
                </Box>
                :
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                <img
                  src="/images/fr_table1.png"
                  alt="fr_table1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Analyse De La Longueur Des Mots{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Il serait intéressant de voir combien de lettres contient le mot
                français moyen. Pour cela, nous créons une nouvelle colonne dans
                le jeu de données appelée "Nombre de Lettres" qui contient le
                nombre de lettres dans chacun des <strong>20.000</strong> mots,
                dont les <strong>20</strong> premiers sont présentés dans le{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Tableau 2
                </Box>{" "}
                ci-dessous:
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                <img
                  src="/images/fr_table2.png"
                  alt="fr_table2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                En utilisant la fonction "Python mean()", nous pouvons
                maintenant trouver la valeur moyenne de la colonne "Nombre de
                Lettres" et voir que le mot français moyen contient{" "}
                <strong>6,96</strong> lettres, comme indiqué ci-dessous dans
                l'extrait de codage:
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                {" "}
                <img
                  src="/images/fr_codesample1.png"
                  alt="fr_codesample1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                En créant un histogramme montrant les valeurs les plus
                fréquentes dans la colonne "nombre des lettres", nous pouvons
                voir la gamme de longueurs des <strong>20.000</strong> mots
                français les plus courants. La{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Figure 1
                </Box>{" "}
                ci-dessous montre que la plupart des mots français (
                <strong>3.608</strong>) comportent <strong>sept</strong> lettres
                (c'est-à-dire que le mode de la colonne intitulée "nombre de
                lettres" est de <strong>sept</strong>).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                <img
                  src="/images/fr_figure1.png"
                  alt="fr_figure1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                L'extrait de codage Python ci-dessous révèle les mots français
                les plus longs que la plupart des locuteurs natifs connaissent
                (il existe bien sûr des mots beaucoup plus longs qui sont plus
                obscurs). Les trois mots les plus longs de la liste des{" "}
                <strong>20.000</strong> mots les plus fréquemment utilisés en
                conversation sont <strong>«approximativement»</strong>,{" "}
                <strong>«approvisionnement»</strong>, et{" "}
                <strong>«cinématographique» </strong>(les trois ont{" "}
                <strong>17</strong> lettres).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                <img
                  src="/images/fr_codesample2.png"
                  alt="fr_codesample2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Analyse de la Première Lettre{" "}
              </Typography>
            </Grid>
            <Grid item>
              Il serait également intéressant de voir quelles lettres se
              trouvent le plus souvent au début des mots. Pour cela, nous
              pouvons créer une autre colonne dans l'ensemble de données appelée
              "première lettre", comme indiqué dans le{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Tableau 3
              </Box>{" "}
              ci-dessous.
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                {" "}
                <img
                  src="/images/fr_table3.png"
                  alt="fr_table3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              D'ici nous pouvons créer un graphique à barres{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                (Figure 2)
              </Box>{" "}
              montrant les premières lettres les plus courantes des mots
              français. Chacune des 26 lettres de l'alphabet français
              (c'est-à-dire celles sans diacritiques ni ligatures) est
              représentée dans le tableau, et nous pouvons voir que la lettre
              initiale la plus fréquente dans les <strong>20.000</strong> mots
              français les plus courants est "<strong>c</strong>" (
              <strong>2.181</strong> mots), et la moins fréquente est "
              <strong>x</strong>" (<strong>4</strong> mots).
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                La partie droite de la{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Figure 2
                </Box>{" "}
                montre également que, parmi les <strong>16</strong> lettres de
                l'alphabet français avec signes diacritiques et ligatures (dont
                fait partie le graphème "<strong>ç</strong>"), neuf se trouvent
                au début des <strong>20.000</strong> mots les plus courants.{" "}
                <strong>451</strong> de ces mots commencent par "
                <strong>é</strong>" (par exemple "était" -{" "}
                <strong>Nº 53</strong> - ou "évident" -{" "}
                <strong>Nº 2.342</strong>) tandis que seulement{" "}
                <strong>un</strong> mot (c'est-à-dire la préposition "
                <strong>à</strong>" (<strong>Nº 13</strong>) qui a seulement une
                lettre) commence par la lettre <strong>'à'</strong>. Aucun mot
                parmi les <strong>20.000</strong> mots français les plus
                courants ne commence par les lettres diacritées/ligaturées "
                <strong>æ</strong>
                ", "<strong>ë</strong>", "<strong>ï</strong>", "
                <strong>ù</strong>
                ", "<strong>î</strong>", "<strong>ü</strong>", ou "
                <strong>ÿ</strong>".
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/fr_figure2.png"
                  alt="fr_figure2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Analyse de la Dernière Lettre{" "}
              </Typography>
            </Grid>
            <Grid item>
              L'ensemble de données peut également nous montrer quelle lettre
              termine le plus souvent les mots français. Pour voir cela, nous
              pouvons créer une autre colonne dans l'ensemble de données appelée
              "dernière lettre", comme indiqué dans le{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Tableau 4
              </Box>{" "}
              ci-dessous.
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                {" "}
                <img
                  src="/images/fr_table4.png"
                  alt="fr_table4"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              D'ici nous créons un autre graphique à barres{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                (Figure 3)
              </Box>{" "}
              montrant les dernières lettres les plus courantes des{" "}
              <strong>20.000</strong> mots français les plus communs. Les mots
              dérivés du même lemme ont des entrées différentes dans la liste,
              par exemple, "<strong>maison</strong>" est classé{" "}
              <strong>Nº 181</strong> et "<strong>maisons</strong>" est classé{" "}
              <strong>Nº 2.088</strong>. À cet effet, nous pouvons voir que les
              mots français se terminent le plus souvent par la lettre "
              <strong>e</strong>" (<strong>5.140</strong> mots - par exemple, de
              nombreux noms et adjectifs au féminin), suivi de la lettre "
              <strong>s</strong>" (<strong>4.300</strong> mots - par exemple, la
              forme plurielle de nombreux noms et adjectifs).{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Figure 3{" "}
              </Box>{" "}
              montre aussi que seulement <strong>six</strong> mots dans la liste
              de <strong>20.000</strong> se terminent par un "<strong>j</strong>
              " et <strong>quatre</strong> se terminent par "<strong>q</strong>
              ". C'est aussi intéressant de voir que parmi les lettres avec
              signes diacritiques et ligatures, le "<strong>ù</strong>" se
              trouve à la fin de seulement un mot (l'adverbe "
              <strong>où</strong>" - Nº <strong>67</strong>). Aucun mot dans la
              liste de <strong>20.000</strong> se termine par "
              <strong>æ</strong>", "<strong>œ</strong>", "<strong>ç</strong>", "
              <strong>ü</strong>", "<strong>î</strong>", ou "<strong>ÿ</strong>
              ".
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/fr_figure3.png"
                  alt="fr_figure3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Analyse de la Lettre la Plus Fréquente{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Une autre analyse intéressante consiste à déterminer, à partir
                de la liste des <strong>20.000</strong> mots, quelles lettres de
                l'alphabet apparaissent le plus souvent dans les mots français.
                Pour voir cela nous pouvons créer un dictionnaire Python pour
                chaque lettre de l'alphabet, où chaque lettre est la clé et la
                valeur est le nombre de fois que la lettre apparaît dans les
                mots de la liste. À partir du dictionnaire nouvellement créé,
                nous pouvons créer un autre graphique à barres (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Figure 4
                </Box>
                ) avec deux couleurs différentes pour différencier les voyelles
                (en rouge) et les consonnes (en vert).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/fr_figure4.png"
                  alt="fr_figure4"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Figure 4
                </Box>{" "}
                montre que "<strong>e</strong>" est de loin la lettre (et la
                voyelle) la plus fréquente dans les <strong>20.000</strong> mots
                français les plus courants, avec <strong>17.667</strong>{" "}
                occurrences. Excluant les lettres diacritées/ligaturées, "
                <strong>w</strong>" est la moins fréquente (<strong>272</strong>{" "}
                se trouvent parmi les <strong>20.000</strong> mots).
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Parmi les lettres diacritées/ligaturées, "<strong>é</strong>"
                est de loin la plus fréquente (<strong>4.312</strong> fois),
                tandis que deux des lettres avec un tréma, "<strong>ä</strong>"
                et "<strong>ü</strong>", qui indiquent des mots d'origine
                allemande, se trouvent seulement <strong>une</strong> et{" "}
                <strong>trois</strong> fois sur la liste, respectivement (par
                exemple, "<strong>fräulein</strong>" - Nº{" "}
                <strong>12.780</strong>" et "<strong>führer</strong>" - Nº{" "}
                <strong>4.446</strong>). En plus, "<strong>ù</strong>" se trouve
                seulement <strong>une</strong> fois, et "<strong>ë</strong>" se
                trouve <strong>sept</strong> fois. Deux lettres dans l'alphabet
                français, "<strong>ÿ</strong>" et "<strong>æ</strong>", n'ont
                aucune occurrence dans le liste et se trouvent, dans le cas de
                cette dernière, seulement dans des mots plus obscurs (par
                exemple "<strong>æthuse</strong>") et dans le cas de cette
                première, seulement dans des noms propres (par exemple la
                commune de "<strong>Aÿ-Champagne</strong>", dans le Marne).
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Parmi les consonnes, "<strong>r</strong>" est la plus fréquente
                (<strong>12.255</strong> occurrences dans la liste), et "
                <strong>ç</strong>" est la moins fréquente (<strong>70</strong>{" "}
                occurrences).
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Catégorie Grammaticale des Mots Français{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Une autre analyse intéressante consiste à voir la catégorie
                grammaticale des mots français les plus fréquents. Le jeu de
                données n'inclut pas les catégories grammaticales, mais nous
                pouvons les ajouter manuellement (ou via une API) pour les{" "}
                <strong>200</strong> premiers mots. Avec cette nouvelle colonne,
                nous pouvons faire quelques diagrammes circulaires avec les
                résultats.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                Catégories Grammaticales des 20 Mots Français les Plus Communs{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Le graphique circulaire{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Figure 5)
                </Box>{" "}
                ci-dessous montre les catégories grammaticales des{" "}
                <strong>20</strong> mots les plus courants en français. (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Remarque
                </Box>
                : certains mots peuvent faire partie de plusieurs catégories
                grammaticales, par exemple le mot "que" - <strong>9e</strong>{" "}
                plus courant - qui peut être une conjonction ou un pronom, ou le
                mot "ce" - <strong>17e</strong> plus courant - qui peut être un
                pronom ou un adjectif). <strong>Quatre articles</strong> en
                français ("le", "la", "les", et "un") sont inclus dans les{" "}
                <strong>20</strong> mots les plus courants ainsi que{" "}
                <strong>sept pronons</strong> ("je", "vous", "tu", "il", "ce",
                "on" et "ça"), <strong>trois prépositions</strong> ( "de", "à"
                et "en"), <strong>deux verbes</strong> ou leurs dérivés ( "est",
                et "a"), <strong>deux conjonctions</strong> ("que" et "et"),{" "}
                <strong>un adverbe</strong> ("pas") et{" "}
                <strong>une particule</strong> ("ne"). Curieusement,{" "}
                <strong>aucun nom commun</strong> ne constitue les{" "}
                <strong>20</strong> mots français les plus courants.
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/fr_figure5.png"
                  alt="fr_figure5"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                Catégories Grammaticales des 200 Mots Français les Plus Communs{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h8" color="black" align="left">
                Lorsque la liste est étendue pour inclure les{" "}
                <strong>200</strong> mots les plus communs, le graphique
                circulaire{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Figure 6)
                </Box>{" "}
                change considérablement. Les noms, les verbes, les adverbes et
                les adjectifs représentent désormais presque{" "}
                <strong>trois-quarts</strong> du diagramme circulaire, tandis
                que les articles, les prépositions, les pronoms et les
                conjonctions constituent <strong>la minorité</strong>. Cela est
                logique car en français il y a des milliers de noms, verbes,
                adverbes et adjectifs (et le nombre de ces types de mots
                augmente chaque année), alors que il y'a relativement peu de
                prépositions, d'articles et de conjonctions (dont le nombre est
                généralement resté stable au fil des années). C'est aussi
                intéressant de noter que le nom le plus commun en français (à
                part le mot <strong>"pas"</strong> - Nº <strong>4</strong> - qui
                est plus souvent utilisé comme adverbe) est "
                <strong>chose</strong>" (qui est en tout le <strong>92</strong>e
                mot le plus commun).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/fr_figure6.png"
                  alt="figure6"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 10 }}
              >
                RÉSUMÉ DES CONCLUSIONS{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left" p={2}>
                Les conclusions les plus importantes de cette analyse sont les
                suivantes:
              </Typography>
              <List>
                {summaryOfFindings.map((summary) => (
                  <ListItem key={summary}>
                    <ListItemText sx={{ marginLeft: 2 }} primary={summary} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default FrenchData;
